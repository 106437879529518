import {newCOLORS} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    max-width: 250px;
    width: 100%;
    position: relative;
    ${(props) => (props.style ? props.style : '')};
`;

export const StyledInput = styled.input`
    padding: 12px 8px;
    font-size: 16px;
    line-height: 20px;
    margin-right: 16px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${newCOLORS.gray200};
    background: #fff;
    color: ${newCOLORS.gray500};

    &:disabled {
        opacity: 0.5;
        background: ${newCOLORS.gray100};
    }

    ${({meta}) =>
        meta &&
        !meta.active &&
        meta.error &&
        `
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1), inset -1px -1px 1px rgba(0, 0, 0, 0.1), inset 0 0 2px #e01e63;
  `}

    ${({inputStyle}) => inputStyle};
`;

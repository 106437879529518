import {COLORS, newCOLORS} from 'core/styles';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
    display: inline-block;
    min-width: fit-content;
    max-width: 250px;
    width: 100%;
    margin: 0 0 16px;
    ${(props) => (props.$css ? props.$css : '')}
`;

export const InnerStyled = styled.div`
    background-color: ${(props) => (props.disabled ? newCOLORS.gray100 : '#fff')};
    border-radius: 4px;
    border: 1px solid ${newCOLORS.gray200};
    height: 46px;
    margin: 0 auto;
    display: block;
    text-align: center;
    position: relative;
    padding: 0;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const SelectStyled = styled.select`
    cursor: pointer;
    padding-left: 10px;
    -webkit-appearance: none;
    border-radius: inherit;
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    height: 44px;
    padding-right: 25px;
    background-image: linear-gradient(45deg, transparent 50%, ${COLORS.primary} 50%),
        linear-gradient(135deg, ${COLORS.primary} 50%, transparent 50%);
    background-position: right 12px top 50%, right 7px top 50%;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    ${(props) => (props.$css ? props.$css : '')}
`;

import {COLORS, darken} from 'core/styles';
import styled, {css} from 'styled-components';
import {Field} from 'redux-form';

export let Container = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 300px;
    text-align: left;
`;

export let Previous = styled.div`
    position: absolute;
    color: ${darken(COLORS.grayLight, 15)};
    top: 22%;
    font-size: 16px;
    cursor: pointer;
    left: -25px;

    &:hover {
        color: ${darken(COLORS.grayLight, 25)};
    }

    &:active {
        color: ${darken(COLORS.grayLight, 30)};
    }
`;

export const RemoveButton = styled.span`
    color: ${COLORS.danger};
    cursor: pointer;
    padding: 2px 5px;
    border: 1px solid ${COLORS.danger};
    border-radius: 3px;
`;

export let Next = styled(Previous)`
    left: auto;
    right: -25px;
`;

export let PhotoFieldCss = css`
    margin-top: 10px;
    margin-bottom: 15px;
`;

export let PhotoCss = css`
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: ${COLORS.gray};
`;

export let TitleFieldCss = css`
    width: 100%;
    margin-bottom: 10px;
    max-width: none;
`;

export let CaptionFieldCss = css`
    display: inline-block;
    max-width: 145px;
    margin-right: 10px;
`;

export let AltTextFieldCss = css`
    display: inline-block;
    max-width: 145px;
`;

export let ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 16px;
    margin-top: 16px;
`;

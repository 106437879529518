'use client';
import React from 'react';
import {Field} from 'redux-form';

import {classReducer, get} from 'utils/helpers';
import PhotoInputStyled from '../../../form/components/PhotoInputStyled/PhotoInputStyled';
import CancelButtonStyled from '../../../form/components/CancelButton/CancelButtonStyled';
import {ErrorStyled} from '../../../form/styles';
import ModalWrapperStyled from '../../../modal/components/ModalWrapperStyled/ModalWrapperStyled';
import SubmitButtonStyled from '../../../form/components/SubmitButtonStyled/SubmitButtonStyled';

import {
    AltTextFieldCss,
    ButtonContainer,
    CaptionFieldCss,
    Container,
    Next,
    PhotoCss,
    PhotoFieldCss,
    Previous,
    RemoveButton,
    TitleFieldCss,
} from './styles';
import TextInput from 'features/form/components/TextInput/TextInput';
import SubmitButton from 'features/form/components/SubmitButton/SubmitButton';
import Button from 'features/generic/components/Button/Button';

export default function (props) {
    const name = props.fieldName;
    const total = props.photos.length;
    const error = get(props, 'error');

    const removeClasses = classReducer({
        'photo-modal__remove': !props.isRemoving,
        'photo-modal__remove removing': props.isRemoving,
    });

    const handleClose = () => {
        props.editPhoto();
        props.editField(false);
    };

    return (
        <ModalWrapperStyled handleClose={handleClose}>
            <Container>
                {props.index > 0 && (
                    <Previous onClick={() => props.editPhoto(props.index - 1)}>
                        ◀
                    </Previous>
                )}
                {props.index < total - 1 && (
                    <Next onClick={() => props.editPhoto(props.index + 1)}>▶</Next>
                )}
                {props.index !== total && (
                    <Button
                        theme="invertWarning"
                        onClick={props.removePhoto}
                        disabled={props.isRemoving}>
                        {props.isRemoving ? 'Removing...' : 'Remove'}
                    </Button>
                )}
                <div>
                    <Field
                        $css={PhotoFieldCss}
                        required
                        name={name + '.file_name'}
                        component={PhotoInputStyled}
                        label="Photo (5MB Maximum)"
                        photoCss={PhotoCss}
                        width={300}
                        height={180}
                    />
                </div>
                {error && <ErrorStyled>{error}</ErrorStyled>}
                <Field
                    required
                    name={name + '.title'}
                    component={TextInput}
                    label="Title"
                    placeholder="Courtyard"
                />
                <Field
                    name={name + '.caption'}
                    component={TextInput}
                    label="Caption"
                    placeholder="The view from the front of our facility"
                />
                <Field
                    name={name + '.altText'}
                    component={TextInput}
                    label="Alternate Text"
                    placeholder="Main Entrance"
                />
                <ButtonContainer>
                    <Button theme="invert" onClick={handleClose}>
                        Cancel
                    </Button>
                    <SubmitButton {...props} />
                </ButtonContainer>
            </Container>
        </ModalWrapperStyled>
    );
}

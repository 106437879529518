import {COLORS} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    display: inline-block;
    margin-bottom: 15px;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')}
`;

export const PhotoContainer = styled.div`
    position: relative;
    margin: 0,
    cursor: pointer;
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};
    height: ${(props) => (props.height ? props.height + 'px' : '100%')};
    background-color: ${(props) => (props.src ? 'transparent' : '#C4C4C4')};
    ${(props) => (props.photoCss ? props.photoCss : '')};
`;

export let FileInput = styled.input`
    cursor: pointer;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    left: 0;
    opacity: 0;
`;

export const Plus = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-12px, -12px);
        background-color: #eee;
        opacity: 1;
        color: ${COLORS.primary};
        width: 24px;
        height: 24px;
        border-radius: 4px;
        content: '+';
        font-size: 22px;
        line-height: 23px;
    }
`;
